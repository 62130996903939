<template>
    <base-page>
        <titlu-pagina Titlu="Raport de organizare" :AdaugaVisible='false' />    
        
        <div style="background-color: #fff">
            <div class="buton" style="width: 100%; display: flex; justify-content: flex-end; padding: 7px 10px;">
                <el-button  plain round type="success" @click="downlaod_raport()">Descarca raport</el-button>
            </div>
            <el-tree :data="Structura" :props="defaultProps" :default-expand-all='true' :expand-on-click-node="false">
                <span class="custom-tree-node" slot-scope="{ node, data }">
                    <span>{{data.Nume}}</span>
                    <span style="margin-left:15px;color:blue">{{data.NumeSef}}</span>
                    <span>
                        <el-button
                            type="text"
                            size="mini"
                            @click="modal_angajati(data.Id)"
                            >
                            Info
                        </el-button> 
                        <span style="opacity: 0.5; font-size: 12px">
                            | Numar angajati: {{data.NumarAngajati ? data.NumarAngajati : '0'}} / {{data.MaxNrAngajati}}
                        </span>                  
                    </span>
                </span>
                
            </el-tree> 
        </div>

         <el-dialog title="Utilizatori" :visible.sync="showPopupAngajati" class="my-dialog-class" >
            <el-table :data="angajati">
                <el-table-column type="index"></el-table-column>
                <el-table-column prop="NumeAngajat" label="Nume"></el-table-column>
                <el-table-column prop="NumeFunctie" label="Functie"></el-table-column>
                <el-table-column
                    fixed="right"
                    label="Actiuni"
                    width="120">
                    <template slot-scope="scope">
                        <el-button type="primary" icon="el-icon-view" circle @click="show_dialog_profile(scope.row)" />
                    </template>
                </el-table-column>
            </el-table>
            <span slot="footer" class="dialog-footer" >
                <el-button @click="showPopupAngajati=false" > Renunta  </el-button>
            </span>
        </el-dialog>
        <el-dialog width="60%" title="Profil utilizator" :visible.sync="showPopupProfil" class="my-dialog-class" >
            <simple-box icon='el-icon-user-solid' background='#43a047' v-if="mesajEroare == ''">
                <template v-slot:title>
                    <user-box background='#43a047'>                        
                        <template v-slot:avatar>
                            <img width="100%" height="100%" :src="selectedObject.Item.AvatarURL" class="profilePic" onerror="this.onerror=null; this.src='@/assets/default.jpg'"/>
                        </template>
                    </user-box>
                </template>
                <template v-slot:content>
                    <div class="container-fluid">
                        <el-form label-position="top" :inline="false" label-width="100%"  :model='selectedObject'  ref='my-form' v-loading="loadingVisible">            
                            <el-row :gutter="15" >
                                <el-col :span='7'>
                                    <el-form-item label='Nume'>
                                        <el-input disabled  class='full-width' v-model='selectedObject.Item.Nume' />
                                    </el-form-item>
                                </el-col>
                                <el-col :span='7'>
                                    <el-form-item label='Email'>
                                        <el-input disabled class='full-width' v-model='selectedObject.Item.Email' />
                                    </el-form-item>
                                </el-col>   
                                <el-col  v-if="selectedObject.Item.CV_URL != '' && selectedObject.Item.CV_URL  != null" :span='4'>
                                <el-form-item label='CV'>
                                        <div><el-button type="success" @click="openCV(selectedObject.Item.CV_URL)">Vizualizare</el-button></div>
                                    </el-form-item>
                                </el-col>      
                            </el-row>

                            <el-row :gutter="15">
                                <el-col :span="7">
                                        <el-form-item label='Functie'>
                                        <el-input  class='full-width' v-model='selectedObject.Item.NumeFunctie' disabled/>
                                    </el-form-item>
                                </el-col>

                                <el-col :span="7">
                                        <el-form-item label='Numar de telefon'>
                                        <el-input  class='full-width' v-model='selectedObject.Item.Angajat.Telefon' disabled/>
                                    </el-form-item>
                                </el-col>
                            </el-row>
                        </el-form>  
                    </div>
                </template>
            </simple-box>
            <span v-else class="text-center"> {{ mesajEroare }} </span>
            <span slot="footer" class="dialog-footer" >
                <el-button @click="closePopupProfil" > Renunta  </el-button>
            </span>
        </el-dialog>

    </base-page>
</template>

<script>
import settings from "@/backend/LocalSettings";
import BasePage from "@/pages/BasePage";
import UserBox from '@/components/UserBox';
import TitluPagina from '@/widgets/TitluPagina';
import SimpleBox from '@/components/SimpleBox';   
import { createLogger } from 'vuex';

export default {
    name: "raport_organizare",
    extends: BasePage,
    components: {
        'base-page': BasePage,
        'titlu-pagina': TitluPagina,
        'simple-box'  : SimpleBox,   
        'user-box'  : UserBox,
    },
    data () {
        return {            
            defaultProps: {
                children: 'Children',
                label: 'Nume'
            },
            Structura:[],
            showPopupAngajati:false,
            selectedObject: {},
            mesajEroare: '',
            showPopupProfil: false,
            angajati:[]
        }
        
    },
    methods: {

        downlaod_raport: async function() {
            window.open(`${this.base_url}/raport_organizare/download_raport`);
        },

        async get_info() {
            this.refresh_info();
        },

        async refresh_info(){
            this.showPopupAngajati  = false
            var response            = await this.post("raport_organizare/index", {} );
            this.Structura          = response.Results;
            this.select_menu_item('raport_organizare');
        },

        openCV(url) {
            window.open(url)
        },

        async closePopupProfil(){
            this.showPopupProfil = false;
            setTimeout(() => {
                this.selectedObject = {};
            }, 5000);
            this.mesajEroare = '';

        },

        async show_dialog_profile(user){
            try{
                let res = await this.post("Utilizatori/get_angajat_by_id", {Id: user.Id})
                console.log(res);
                if(typeof res == 'string'){
                    this.mesajEroare = 'Nu exista user asociat angajatului!!';
                    this.showPopupProfil = true;
                }else {
                    this.mesajEroare = '';
                    this.selectedObject = res;
                    this.showPopupProfil = true;
                }

            }catch(error){
                this.mesajEroare = 'Nu exista user asociat angajatului!!';
                this.showPopupProfil = true;
                console.log(error);
            }
            
        },

        async modal_angajati(id){
            console.log(id);
            var response    = await this.post("raport_organizare/get_angajati_departament", {id_grupa:id} );
            this.angajati   = response.Results
            this.showPopupAngajati = true
        }
    },
    mounted(){
        this.base_url = settings.BASE_URL;
        this.get_info();
    }
};
</script>

<style lang="less" scoped>


</style>
